.container {
    border-top: 1px solid #111;
    // margin-top: 100px;
    display: flex;
    justify-content: center;
    background-color: #000;
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
    // position: relative;

    .contentContainer {
        // border: 1px solid #f00;
        margin: 15px;
        width: 100%;
        max-width: 1400px;
        padding: 15px;

        .row1 {
            display: flex;
            gap: 30px;

            @media only screen and (width<850px) {
                flex-wrap: wrap;
                gap: 30px;
            }

            .section1,
            .section2 {
                // border: 1px solid #fff;
                width: 100%;
                display: flex;
                gap: 30px;

                @media only screen and (width<480px) {
                    flex-wrap: wrap;
                }
            }

            .section1 {
                .col1 {
                    display: flex;
                    flex-direction: column;
                    align-items: space-between;

                    .logoContainer {
                        // border: 1px solid #fff;
                        position: relative;
                        max-width: 200px;
                        height: 50px;
                        border-radius: 3px;
                        display: flex;
                        align-items: stretch;
                        // gap: 10px;
                        overflow: hidden;

                        .logoAnimationDiv {
                            flex: 0 0 50px;
                            position: relative;
                            // border: 1px solid #f00;

                            .logoMainDiv {
                                position: absolute;
                                margin: auto;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                width: 40px;
                                height: 24px;
                                background-color: #fff;
                                border-radius: 3px;
                                // box-shadow: 0 0 5px 5px #fff;
                                animation: rotateDIV 30s linear infinite;

                                @keyframes rotateDIV {
                                    0% {
                                        transform: rotateZ(0);
                                    }
                                    100% {
                                        transform: rotateZ(360deg);
                                    }
                                }

                                &::before,
                                &::after {
                                    content: "";
                                    position: absolute;
                                    width: inherit;
                                    height: inherit;
                                    background-color: inherit;
                                    border-radius: inherit;
                                    box-shadow: inherit;
                                }
                                &::before {
                                    transform: rotateZ(60deg);
                                }
                                &::after {
                                    transform: rotateZ(-60deg);
                                }
                            }

                            .logoText {
                                font-family: "Bagel Fat One", system-ui;
                                color: orangered;
                                font-size: 16px;
                                // border: 1px solid #ff0;
                                position: absolute;
                                margin: auto;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .logoNameDialog {
                            width: 100%;
                            // border: 1px solid #f00;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            justify-content: stretch;
                            align-items: stretch;

                            .name {
                                height: 100%;
                                // border: 1px solid #ff0;
                                font-family: "Bagel Fat One", system-ui;
                                // color: orangered;
                                font-size: 16px;
                                text-shadow: 0 0 5px #fff;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .dialog {
                                // border: 1px solid #ff0;
                                color: #aaa;
                                font-size: 9px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }

                    .mobileStoreContainer {
                        display: flex;
                        gap: 15px;
                        margin-top: 15px;
                        flex-wrap: wrap;

                        img {
                            flex: 0 0 100px;
                            height: 30px;
                            border-radius: 5px;
                        }
                    }
                }
            }

            .section2 {
                .col4 {
                    display: flex;
                    flex-direction: column;
                    align-items: space-between;
                    justify-content: space-between;

                    .address {
                        color: #fff;
                        line-height: 20px;
                    }

                    .socialLinksContainer {
                        display: flex;
                        gap: 12px;
                        margin-top: 30px;

                        .link {
                            text-decoration: none;
                            background-color: #000;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            color: #aaa;
                            transition: box-shadow 0.2s ease;
                            box-shadow: 0 0 10px 0.5px #666;

                            &:hover {
                                // transform: scale(1.5);
                                color: #fff;
                                box-shadow: 0 0 15px 1px #fff;
                            }

                            .socialIcon {
                                font-size: 20px;
                                padding: 3px;
                            }
                        }

                        .facebookLink {
                            // color: #4267b2;
                        }
                        .youtubeLink {
                            // color: #be2e2a;
                        }
                        .linkedinLink {
                            // color: #0e72a3;
                        }
                        .githubLink {
                            // color: #fafbfc;
                        }
                    }
                }
            }

            .section1,
            .section2 {
                .col1,
                .col2,
                .col3,
                .col4 {
                    // border: 1px solid #f00;
                    width: 100%;
                    // max-width: 150px;

                    .navlink {
                        color: #fff;
                        text-decoration: none;
                        // font-size: 10px;
                        // height: 20px;
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .row2 {
            border-top: 1px solid #aaa;
            margin-top: 30px;
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border: 1px solid #aaa;

            @media only screen and (width<600px) {
                display: block;
                // flex-direction: column;
                // flex-wrap: wrap;
                // gap: 15px;
                // padding-top: 15px;
                // align-items: flex-start;

                .col2 {
                    // justify-content: flex-start;
                }
            }

            .col1,
            .col2 {
                // border: 1px solid #fff;
                font-size: 8px;
                color: #fff;
                // width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
            }

            .col2 {
                // justify-content: flex-end;
            }
        }
    }
}
