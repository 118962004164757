.container {
    // border: 1px solid #f00;
    height: 100vh;
    overflow: hidden;
    position: relative;

    .header {
        height: 70px;
        // border: 1px solid #f00;
        // z-index: 99;
    }

    .main {
        height: calc(100vh - 70px);
        // border: 1px solid #f00;
        overflow: auto;

        .router {
            min-height: calc(100vh - 70px);
            // border: 1px solid #f00;
        }

        .footer {
            min-height: 300px;
        }
    }

    .arrowUp {
        position: fixed;
        display: none;
        bottom: 30px;
        right: 25px;
        font-size: 50px;
        cursor: pointer;
        color: orangered;
        background-color: black;
        border-radius: 50%;
        z-index: 50;
    }
}
