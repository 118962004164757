.container {
    border: 1px solid #fff;
    // padding: 15px;
    height: calc(100% - 70px);

    .searchInputContainer {
        display: flex;
        height: 40px;

        .searchInput {
            background-color: #222;
            border: none;
            border: 1px solid #fff;
            margin: 3px;
            outline: none;
            color: #aaa;
            padding-left: 15px;
            width: 100%;
            height: 100%;
        }
    }

    .navLinksContainer {
        border: 1px solid #fff;
        margin: 3px;
        margin-top: 5px;
        height: calc(100% - 50px);

        position: relative;
        overflow: auto;
        padding-bottom: 40px;

        .homeNavLink,
        .aboutNavLink,
        .notesNavLink,
        .projectsNavLink,
        .userNavLink,
        .cookiesPolicyNavLink,
        .disclaimerNavLink,
        .faqNavLink,
        .privacyPolicyNavLink,
        .returnPolicyNavLink,
        .termsAndConditionsNavLink,
        .contributeNavLink {
            height: 40px;
            display: flex;
            align-items: center;
            padding: 15px;
            text-decoration: none;
            color: #fff;
            // border: 1px solid #fff;
            display: flex;
            align-items: center;
            gap: 15px;

            &:hover {
                background-color: #111;
                color: orangered;
            }
        }

        .aboutNavLinksContainer,
        .userNavLinksContainer,
        .projectsNavLinksContainer {
            // border: 1px solid #f00;
            overflow: hidden;
            // height: 0;
            padding-left: 30px;
        }
    }
}