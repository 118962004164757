*,
::before,
::after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    /* font-weight: 400;
    font-style: normal; */
    scroll-behavior: smooth !important;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

pre {
    background-color: aliceblue;
    padding: 15px;
}
