.container {
    border-bottom: 1px solid #222;
    position: relative;
    background-color: #000;
    position: relative;
    z-index: 99;

    .row1 {
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .leftSection {
            // border: 1px solid #f00;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .name {
                font-family: "Orbitron", sans-serif;
                font-optical-sizing: auto;
                font-style: normal;
                font-size: 20px;
                padding: 15px;
                display: flex;
                align-items: flex-start;
                color: #fff;
                letter-spacing: 1px;
                height: 40px;
            }

            .logo {
                height: 100%;
                // border: 1px solid #f00;
                text-align: center;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                font-size: 10px;
                color: gray;
            }
        }

        .rightSection {
            // border: 1px solid #f00;
            display: flex;
            align-items: center;

            .navLink {
                // border: 1px solid #f00;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 30px;
                font-family: "Montserrat", sans-serif;
                font-optical-sizing: auto;
                font-style: normal;
                text-decoration: none;
                color: #aaa;
                transition: color 0.2s ease;
                display: flex;
                gap: 15px;
                align-items: center;
                justify-content: space-between;
                position: relative;

                @media only screen and (width<900px) {
                    display: none;
                }

                &:hover {
                    color: #fff;
                    .caretDown {
                        transform: rotateZ(-180deg);
                    }

                    .aboutNavLinksContainer {
                        height: 160px;
                    }

                    .userNavLinksContainer {
                        height: 240px;
                    }

                    .projectsNavLinksContainer {
                        // border-left: 2px solid orangered;
                        // border-right: 2px solid orangered;
                        height: 240px;
                        overflow: auto;
                    }
                }

                .caretDown {
                    transition: transform 0.2s ease;
                }

                .aboutNavLinksContainer,
                .userNavLinksContainer,
                .projectsNavLinksContainer {
                    position: absolute;
                    top: 100%;
                    width: 300px;
                    height: 0;
                    overflow: hidden;
                    background-color: #111;
                    transition: height 0.2s ease;

                    .aboutNavLink,
                    .userNavLink,
                    .projectsNavLink {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        color: #fff;
                        text-decoration: none;
                        padding: 15px;
                        background-color: #222;

                        &:hover {
                            background-color: #333;
                            color: orangered;
                        }
                    }
                }
                .aboutNavLinksContainer {
                    left: 0;
                }
                .userNavLinksContainer {
                    right: 0;
                }
                .projectsNavLinksContainer {
                    right: 0;
                }
            }

            .homeNavLink,
            .notesNavLink {
                // border: 1px solid #f00;
                position: relative;

                &:hover {
                    .underline {
                        width: 100%;
                    }
                }

                .underline {
                    position: absolute;
                    top: 80%;
                    left: 0;
                    background-color: #fff;
                    height: 1px;
                    width: 0;
                    transition: width 0.2s ease;
                }
            }

            .hamburger {
                // border: 1px solid #f00;
                width: 70px;
                height: 100%;
                position: relative;

                &:hover {
                    background-color: #111;

                    .line {
                        background-color: #fff;
                    }
                }

                .line {
                    position: absolute;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 40px;
                    height: 5px;
                    background-color: #aaa;
                    border-radius: 3px;
                    transition: transform 0.2s ease, background-color 0.2s ease;
                }
                .line:nth-child(1) {
                    transform-origin: left top;
                    transform: translateY(-10px);
                }
                .line:nth-child(2) {
                    transform-origin: center;
                }
                .line:nth-child(3) {
                    transform-origin: left bottom;
                    transform: translateY(10px);
                }
            }
        }
    }

    .row2 {
        // border: 1px solid #f00;
        z-index: 99;
        position: fixed;
        top: 65px;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.9);
        transition: left 0.2s ease;
        z-index: 99;
        display: flex;

        .emptySection {
            height: 100%;
            width: 100%;
        }

        .navLinksContainer {
            height: 100%;
            flex: 0 0 280px;
            background-color: #000;
        }
    }
}
